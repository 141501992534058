define("discourse/plugins/discourse-auto-bookmarks/discourse/initializers/discourse-auto-bookmarks", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/icon-library"], function (_exports, _pluginApi, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function toSnakeCase(string) {
    return string.toLowerCase().split(" ").join("-").replace(/[^A-Za-z0-9-]+/g, '');
  }
  function createBookmark(_, header) {
    let rawText = header.textContent;
    $(header).attr("id", toSnakeCase(rawText));
    $(header).addClass("header-bookmark");
    let spanText = "<span class='header-text'>" + rawText + "</span>";
    let headerContent = "<a href='#" + toSnakeCase(rawText) + "' class='bookmark-link' hidden=true>" + (0, _iconLibrary.iconHTML)('bookmark') + "</a>" + spanText;
    $(header).html(headerContent);
    $(header).hover(function () {
      $(this).find("a").show();
    }, function () {
      $(this).find("a").hide();
    });
  }
  function initializeAutoBookmark(api) {
    api.decorateCooked($elem => $.each($elem.find("h1, h2, h3, h4, h5, h6"), createBookmark));
  }
  var _default = _exports.default = {
    name: "discourse-auto-bookmarks",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.24", initializeAutoBookmark);
    }
  };
});